<template>
  <div>
    <div class="w-content" v-if="true">
      <template v-if="true">
        <h2 class="title">{{$t("Acc_Desc_18")}}</h2>
        <a class="d-flex-center" @click.prevent="updateWaletData">
          <img id="UpdateBalanceButton" src="/img/icon/Reload.png" alt />
        </a>
        <div>
          Delegated string:<br />
          {{data}}
        </div>
      </template>
    </div>
    <div v-else>{{$router.go(-1)}}</div>
  </div>
</template>

<script>
  import {mapState} from "vuex";
  import instance from "../http";
  import i18n from "@/i18n";
  import axios from "axios";

  export default {
    name: "delegation-details",
    props: ["toUpdate"],
    methods: {
      updateWaletData: function () {
        this.$loading(false);
        this.displayedPreloader = true;
        let postData = {
          MethodApi: "GetWalletData",
          PublicKey: this.publicKey,
          NetworkAlias: this.networkAlias
        };
        let postContent = JSON.stringify(postData);
        console.log(postContent);
        new Promise((resolver, reject) => {
          this.$loading(true);
          let httpInstance = axios
            .post("../../api/wallet/GetWalletData", postContent, {
              headers: {
                "Content-Type": "application/json"
              }
            })
            .then(x => {
              this.data = x.data;
              this.result = "";
              console.log("Starting data output");
              console.log(this.data);
              if (this.data != null) {
                this.result += "Balance: " + this.data["balance"] + "\n";
                this.result += "Last transaction: " + this.data["lastTransaction"] + "\n";
                if (this.data["delegated"] == null) {
                  this.result += "No delegations at this moment";
                }
                else {
                  this.result += "Donors:\n";
                  if (this.data["delegated/donors"] == null) {
                    this.result += "No donors\n";
                  }
                  for (it in this.data["delegated/donors"]) {
                    this.result += it["publicKey"] + "      " + it["sum"] + "\t\t" + it["validUntil"];
                  }
                  this.result += "Recipients:\n";
                  if (this.data["delegated/recipients"] == null) {
                    this.result += "No recipients\n";
                  }
                  for (it in this.data["delegated/recipients"]) {
                    this.result += it["publicKey"] + "      " + it["sum"] + "\t\t" + it["validUntil"];
                  }
                }
              }
              console.log("Data received:" + this.result);
              this.$loading(false);

            })
            .catch(x => {
              console.log("Error load data");
              console.log(x);
              this.$loading(false);
            });
        });

        console.log(" - check balance list");
        // console.log(this.balanceList);
      }
    },
    watch: {
      toUpdate: {
        handler: function (value) {
          if (value) {
            this.updateWalet();
            this.$emit("returnUpdateCallback");
          }
        }
      }
    },
    computed: {
    ...mapState({
        publicKey: s => s.publicKey,
        privateKey: s => s.privateKey,
        networkAlias: s => s.networkAlias
      })
    }
  };
  
</script>
